import {
  CustomClassNamesProps,
  KeyMapProps,
} from 'components/codexTable/interfaces/keymap.interfaces';
import { Record } from 'components/codexTable/interfaces/record.interfaces';
import { Image_Source_Types_Enum } from 'graphql/generated/hasura';
import { capitalizeFirstLetter } from 'utilities/functions';
import { localeType } from './TableContent';

interface TableContentMobileProps<T extends Record> {
  keyMap: KeyMapProps;
  columnKeys: string[];
  data: T[];
  actions?: React.FC<T & { locale?: localeType }>;
  locale?: {
    [key: string]: string;
  };
  vertical?: boolean;
  onSeeMore?: () => void;
  canSeeMore?: boolean;
  customClasses?: CustomClassNamesProps;
}

const TableContentMobile = <T extends Record>({
  keyMap,
  columnKeys,
  data,
  actions,
  locale,
  customClasses = {},
  vertical = false,
}: TableContentMobileProps<T>) => {
  return (
    <div className="divide-y desktop:hidden">
      {data.map((each) => {
        return (
          <div
            key={each.id}
            className="p-[30px] flex flex-col gap-2.5 justify-between"
          >
            {columnKeys.map((key) => {
              const value = each[key as keyof Record];
              const { thClass, spanClass } = customClasses[key] || {};
              const FormatValue =
                key in keyMap ? keyMap[key].FormatValue : undefined;
              return (
                <div
                  key={key}
                  className={`flex w-full ${thClass && thClass} ${
                    vertical ? 'flex-col' : ''
                  }`}
                >
                  <strong className="text-base text-dark-gray w-full">
                    {locale && locale[key]
                      ? locale[key]
                      : capitalizeFirstLetter(key)}
                    :
                  </strong>
                  <div
                    className={`text-base text-med-gray font-medium w-full ${
                      value !== Image_Source_Types_Enum.Mobile && 'ml-0'
                    }
                    ${spanClass && spanClass}`}
                  >
                    {FormatValue ? (
                      <FormatValue value={value} entry={each} />
                    ) : (
                      value
                    )}
                  </div>
                </div>
              );
            })}
            {actions && (
              <div className="mt-5">{actions({ ...each, locale })}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TableContentMobile;
