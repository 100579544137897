import { ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../firebase/interfaces';
import { ProviderPendingApproval } from 'app/my-account/pages/provider-pending-approval/ProviderPendingApproval';
import PageLayout from 'layout/PageLayout';
import { AUTH_LOGIN } from 'utilities/routes';
import { Buffer } from 'buffer';

interface Props {
  children: ReactNode;
}

export const AuthenticatedRoute = ({ children }: Props) => {
  const location = useLocation();
  const { isLoggedIn, user } = useContext(AuthContext);
  const decodedRedirect = `${location.pathname ?? ''}${location.search ?? ''}`;
  const redirect =
    decodedRedirect &&
    encodeURIComponent(Buffer.from(decodedRedirect).toString('base64'));
  return isLoggedIn ? (
    user?.role === Roles.PROVIDER && !user.providerEnabled ? (
      <PageLayout>
        <ProviderPendingApproval />
      </PageLayout>
    ) : (
      <>{children}</>
    )
  ) : (
    <Navigate to={`${AUTH_LOGIN}${redirect ? `?redirect=${redirect}` : ''}`} />
  );
};
